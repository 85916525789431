import React from "react";

import { Root } from "./page-wrapper-styles";
import { CenterWrapper } from "../../primitives";

export interface PageWrapperProps {
    children: any;
    withEscapeHatchStylesheet? :any
}

const PageWrapper = ({ children, escapeHatchStylesheet })  => (
    <Root escapeHatchStyles={escapeHatchStylesheet && escapeHatchStylesheet.root}>
        <CenterWrapper>
            { children }
        </CenterWrapper>
    </Root>
);

export default PageWrapper;