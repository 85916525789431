import styled from "styled-components";
import {
    bpMobilePhablet
} from "../../../styles/_theme-variables";
import { fluidFontSizes } from "../../../styles/_calculations";
import { withEscapeHatchStyles } from '../../../styles/sprinkles';

export const Root = withEscapeHatchStyles(styled.div`
    ${fluidFontSizes.bodySize}

    @media screen and (min-width: ${bpMobilePhablet}) {
        padding: 1em;
    }

    @media screen and (max-width: ${bpMobilePhablet}) {
        padding: 0.5em;
    }
`);