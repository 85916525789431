import React, { useContext } from "react";
import { ThemeContext } from 'styled-components';
import styled from 'styled-components';

// Style Override Approach 2: Inject additional styles into an element
export const withEscapeHatchStyles = (Component) => {
    const EscapeHatchedComponent  = styled(Component)`
        ${props => props.escapeHatchStyles}
    `;

    return ((props: any) => <EscapeHatchedComponent {...props} />);
}

// Style Override Approach 2: Inject groups of additional styles into a component
export const withEscapeHatchStylesheet = Component => EscapeHatchStyleSheet => (props: any) => {
    return <Component {...props} escapeHatchStylesheet={EscapeHatchStyleSheet} />;
}

// Style Override Approach 3: Inject the styled elements from the parent as render props
export const withRenderPropElements = Component => RenderPropElements => (props: any) => {        
    return <Component {...props} elements={RenderPropElements} />;
};

// Style Override Approach 4: Inject the styled elements from a factory
export const withContextElements = Component => ElementFactory => (props: any) => {
    const context = useContext(ThemeContext);
    const elementFactory = new ElementFactory(context)
    const elements = elementFactory.getElements();
    return <Component {...props} elements={elements} />;
};